<template>
  <div class="new-window text-lightblue">
    <screen
      :modeType="4"
      :winIndex="winIndex"
      :isPatrol="isPatrol"
      :isGroup="isGroup"
      :isCollect="isCollect"
      :user="user"
    />
  </div>
</template>

<script>
import Screen from '@/views/window/Screen'
import { getParamValue } from '@/tools'

export default {
  name: 'SingleWindow',
  data() {
    return {
      winIndex: -1,
      isPatrol: false,
      isGroup: '0',
      isCollect: '0',
      user: ''
    }
  },
  components: {
    Screen
  },
  computed: {},
  watch: {},
  created() {
    this.winIndex = getParamValue('index')
    this.isPatrol = getParamValue('isPatrol')
    this.user = getParamValue('username')
    //组
    this.isGroup = getParamValue('isGroup')
    //收藏
    this.isCollect = getParamValue('isCollect')
  },
  methods: {}
}
</script>

<style scoped>
.new-window {
  width: 100%;
  height: 100%;
  /* background: rgba(31, 64, 103, 1); */
  background: rgb(0, 0, 0);
}
</style>
